<template>
    <v-dialog :persistent="leaving" v-model="dialog" max-width="500">
        <template v-if="listItem === false" v-slot:activator="{ on }">
            <v-btn small class="secondary--text" text v-on="on">
                <v-icon small left>exit_to_app</v-icon>
                {{ levelLeavingTitle }}
            </v-btn>
        </template>
        <template v-else v-slot:activator="{ on }">
            <v-list-item v-on="on" @click="dialog = true">
                <v-list-item-title>
                    <div class="d-flex align-center">
                        <v-icon color="secondary" left small>exit_to_app</v-icon>
                        {{ levelLeavingTitle }}
                    </div>
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center secondary--text">
                    <v-icon left>exit_to_app</v-icon>
                    <span class="text-uppercase">{{ levelLeavingTitle }}</span>
                </div>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-text class="mt-1">
                <v-alert prominent outlined type="warning" class="mb-0 mt-3">
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold mb-2">Access loss</span>
                        {{ warningText }}
                        <v-divider class="mt-4 secondary" style="opacity: 0.22"></v-divider>
                        <v-checkbox color="secondary" v-model="agreeBox">
                            <template v-slot:label>
                                <span class="secondary--text">I understand</span>
                            </template>
                        </v-checkbox>
                    </div>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="leaving" @click="dialog = false" color="secondary" text>cancel</v-btn>
                <v-btn :loading="leaving" @click="leave" :disabled="!agreeBox" color="error" text>leave</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'LeaveDialog',
    mixins: [enumsData],
    props: {
        levelLeavingTitle: String,
        hierarchicalLevel: String,
        id: String,
        listItem: { type: Boolean, default: false },
        orgDialog: { type: Boolean, default: false }
    },
    data() {
        return {
            dialog: false,
            agreeBox: false,
            leaving: false
        }
    },
    methods: {
        leave: function () {
            this.leaving = true
            let apiURL
            if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.ORGANIZATION) {
                apiURL = `/orgs/${this.$route.params.oid}/leave_async`
            } else if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.SPACE) {
                apiURL = `/spaces/${this.$route.params.sid}/leave`
            }
            this.$axios
                .delete(apiURL)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Successfully revoked your ${this.$props.hierarchicalLevel} role.`,
                        snackBarTimeout: 2000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Could not revoked your ${this.$props.hierarchicalLevel} role.`,
                        snackBarTimeout: 2000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.leaving = false
                    this.dialog = false
                    if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.ORGANIZATION) {
                        this.$store.dispatch('fetchUserOrgs')
                        this.$router.push({ name: 'welcome' })
                    } else if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.SPACE) {
                        this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid).finally(() => {
                            this.$router.push({ name: 'home-dashboard', params: { oid: this.$route.params.oid } })
                        })
                    }
                })
        }
    },
    computed: {
        warningText() {
            if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.SPACE) {
                return 'Leaving a space will revoke your access to all instances and contents inside the space. To regain access to the space, please contact a space administrator.'
            } else if (this.$props.hierarchicalLevel === this.nuvolosObjectTypes.ORGANIZATION) {
                return 'Leaving an organization will revoke your access to all spaces and instances inside the organization. To regain access to the organization, please contact an organization manager.'
            }
            return ''
        }
    },
    watch: {
        orgDialog: function (nextVal) {
            if (nextVal && !this.dialog && this.$props.hierarchicalLevel === this.nuvolosObjectTypes.ORGANIZATION) {
                this.dialog = true
            }
        },
        dialog: function (nextVal, preVal) {
            if (preVal && !nextVal) {
                this.$emit('close', { value: true })
            }
        }
    }
}
</script>
